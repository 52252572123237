/**
 * @param str string | number | null | undefined строка
 * @param unit string размерность default px
 * @return string | undefined
 * */
export function convertToUnit (str,  unit = 'px') {
    if (str == null || str === '') {
        return undefined
    } else if (Number.isNaN(str)) {
        return String(str)
    } else {
        return `${Number(str)}${unit}`
    }
}

export function onlyEngLang(e){
    const char = String.fromCharCode(e.keyCode);
    if (/[0-9a-zA-Z ]/g.test(char)) return true;
    else return  e.preventDefault()
}