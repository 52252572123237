import {getNamesFromArray, getStringFromBoolean} from "@/utils/formData";

export default {
  countCells: 20,
  massive: [
    {
      dataField: 'actions',
      cols: 1,
      rows: 1,
      order: 9,
      class: 'd-flex flex-row align-start justify-center ',
      headerClass: 'd-flex flex-row align-center',
      variable: true,
      text: '',
    },
    {
      dataField: 'nameOlympic',
      cols: 1,
      rows: 1,
      text: 'Олимпиада',
      class: 'd-flex flex-row align-start justify-start ',
      headerClass: 'd-flex flex-row align-center',
      headerStyles: `min-width: 260px; justify-content: flex-start;`,
      order: 0,
      variable: true,
      headerTooltip: true,
      headerTooltipText: () => `Полное наименование олимпиады в соответствии с утвержденным положением об олимпиаде или при его отсутствии в соответствии с проектом положения об олимпиаде`,
      template: (data) => data.olympiad?.name ? data.olympiad?.name : data.olympiad
    },
    {
      dataField: 'canForeignBe',
      cols: 1,
      rows: 1,
      order: 2,
      class: `align-start`,
      headerClass: 'd-flex flex-row align-center',
      variable: true,
      headerTooltip: true,
      headerTooltipText: () => `Полное наименование олимпиады на английском языке`,
      text: `Перевод на английский язык`,
      template: (data) => data.nameOlympicEnglish
    },
    {
      dataField: 'profiles',
      cols: 1,
      rows: 1,
      order: 2,
      variable: true,
      headerClass: 'd-flex flex-row align-center ',
      class: 'px-2 align-start',
      headerStyles: `min-width: 200px`,
      text: `Профили`,
      headerTooltip: true,
      headerTooltipText: () => `Наименования профилей олимпиады`,
      template: (data) => getNamesFromArray(data.profiles)
    },
    {
      dataField: 'startYear',
      cols: 1,
      rows: 1,
      order: 3,
      headerClass: '',
      class: `align-start`,
      variable: true,
      text: `С какого года проводится?`,
      headerTooltip: true,
      headerTooltipText: () => `С какого года проводится олимпиада?`,
    },
    {
      dataField: 'dates',
      cols: 1,
      rows: 1,
      order: 3,
      class: `align-start`,
      headerClass: 'd-flex flex-row align-center',
      variable: true,
      text: `Период проведения `,
      headerTooltip: true,
      headerTooltipText: () => `Период проведения олимпиады в 2023/24 учебном году (от начала первого этапа олимпиады и до завершения последнего этапа олимпиады)`,
      template: (data) => data?.dates?.join(' - ')
    },
    {
      dataField: 'maintainerOrg',
      cols: 1,
      rows: 1,
      order: 3,
      class: `align-start`,
      headerClass: 'd-flex flex-row align-center',
      variable: true,
      headerTooltip: true,
      headerTooltipText: () => `Будет ли ваша организация основным организатором олимпиады?`,
      text: `Организатор олимпиады?`,
      template: (data) => getStringFromBoolean(data.maintainerOrg)
    },
    {
      dataField: 'maintainerOrgName',
      cols: 1,
      rows: 1,
      order: 6,
      class: `align-start`,
      headerClass: 'd-flex flex-row align-center',
      variable: true,
      text: `Организатор`,
      headerTooltip: true,
      headerTooltipText: () => `Полное наименование основного организатора олимпиады (ввести полное наименование по ЕГРЮЛ)`,
      template: (data) => data.maintainerOrgName
    },
    {
      dataField: 'helpersOrgNames',
      cols: 1,
      rows: 1,
      order: 7,
      class: `align-start`,
      headerClass: 'd-flex flex-row align-center',
      variable: true,
      headerTooltip: true,
      headerTooltipText: () => `Полные наименования соорганизаторов олимпиады (ввести полные наименования по ЕГРЮЛ)`,
      text: `Соорганизаторы`,
      template: (data) => data.helpersOrgNames?.join(',<br> ') ?? 'У олимпиады не будет соорганизаторов'
    },
    {
      dataField: 'site',
      cols: 1,
      rows: 1,
      order: 3,
      class: `align-start`,
      headerClass: 'd-flex flex-row align-center',
      variable: true,
      text: `Веб-сайт`,
      headerTooltip: true,
      headerTooltipText: () => `Официальный сайт / официальная страница олимпиады в сети Интернет`,
    },
    {
      dataField: 'paragraphNumber',
      cols: 1,
      rows: 1,
      order: 4,
      class: `align-start`,
      headerClass: 'd-flex flex-row justify-center px-2',
      variable: true,
      headerTooltip: true,
      headerTooltipText: () => `Номер пункта в положении об олимпиаде, где указано условие о возможности участия в ней иностранных граждан / комментарий об отсутствии в положении об олимпиаде ограничений на участие иностранных граждан в этой олимпиаде`,
      text: 'Номер пункта в положении об олимпиаде про участие иностранных граждан ',
    },
    {
      dataField: 'minKnowledgeRequired',
      cols: 1,
      rows: 1,
      order: 7,
      class: `align-start`,
      headerClass: 'd-flex flex-row justify-center px-2',
      variable: true,
      headerTooltip: true,
      headerTooltipText: () => `Будут ли требования к минимальному уровню знаний, необходимых для успешного прохождения олимпиады?`,
      text: 'Наличие требований к минимальному уровню знаний',
      template: (data) => getStringFromBoolean(data.minKnowledgeRequired)
    },
    {
      dataField: 'minKnowledgeRequiredInfo',
      cols: 1,
      rows: 1,
      order: 8,
      class: `align-start`,
      headerClass: 'd-flex flex-row justify-center px-2',
      variable: true,
      text: 'Требования к минимальному уровню знаний',
      headerTooltip: true,
      headerTooltipText: () => `Требования к минимальному уровню знаний, необходимых для успешного прохождения олимпиады`,
      template: (data) => data.minKnowledgeRequired ? data.minKnowledgeRequiredInfo : 'Требований к минимальному уровню знаний, необходимых для успешного прохождения олимпиады, не было'
    },
    {
      dataField: 'foreignLanguages',
      cols: 1,
      rows: 1,
      order: 5,
      class: 'px-2 align-start',
      headerClass: 'd-flex flex-row justify-center px-2',
      variable: true,
      headerTooltip: true,
      headerTooltipText: () => `Наименования иностранных языков олимпиадных заданий `,
      text: 'Иностранные языки олимпиадных заданий',
      template: (row) => {
        return getNamesFromArray(row.foreignLanguages)
      }
    },
    {
      dataField: 'foreignStates',
      cols: 1,
      rows: 1,
      order: 6,
      class: 'px-2 align-start',
      headerClass: 'd-flex flex-row justify-center px-2',
      variable: true,
      headerTooltip: true,
      headerTooltipText: () => `Наименования иностранных государств, на территориях которых очно планируется провести не менее одного этапа олимпиады`,
      text: 'Иностранные государства, где очно проводилось не менее одного этапа',
      template: (row) => {
        return getNamesFromArray(row.foreignStates)
      }
    },
    {
      dataField: 'distance',
      cols: 1,
      rows: 1,
      order: 4,
      class: `align-start`,
      headerClass: 'd-flex flex-row justify-center px-2',
      variable: true,
      headerTooltip: true,
      headerTooltipText: () => `Будет ли возможность дистанционного участия иностранных граждан в олимпиаде?`,
      text: 'Дистанционное участие иностранных граждан?',
      template: (data) => getStringFromBoolean(data.distance)
    },
    {
      dataField: 'informChannels',
      cols: 1,
      rows: 1,
      order: 5,
      class: 'px-2 align-start',
      headerClass: 'd-flex flex-row justify-center px-2',
      headerStyles: `min-width: 200px;`,
      variable: true,
      headerTooltip: true,
      headerTooltipText: () => `Каналы информирования об олимпиаде, которые планируется использовать для привлечения внимания иностранных граждан к участию в олимпиаде`,
      text: 'Каналы информирования',
      template: (row) => {
        return getNamesFromArray(row.informChannels, 'name', ', ', 'Каналы информирования об олимпиаде для привлечения внимания иностранных граждан к участию в олимпиаде использовать не планируется')
      }
    },
    {
      dataField: 'advertisements',
      cols: 1,
      rows: 1,
      order: 6,
      class: `align-start`,
      headerClass: 'd-flex flex-row align-center',
      variable: true,
      headerStyles: `min-width: 200px;`,
      text: 'Мероприятия по продвижению ',
      headerTooltip: true,
      headerTooltipText: () => `Мероприятия по продвижению олимпиады среди иностранных граждан, которые планируется провести`,
      template: (row) => {
        return getNamesFromArray(row.advertisements, 'name', ', ', 'Мероприятия по продвижению олимпиады среди иностранных граждан проводить не планируется')
      }
    },
    {
      dataField: 'experts',
      cols: 1,
      rows: 1,
      order: 7,
      class: `align-start`,
      headerClass: 'd-flex flex-row justify-center px-2',
      headerStyles: `min-width: 200px;`,
      variable: true,
      headerTooltip: true,
      headerTooltipText: () => `ФИО и квалификация международных экспертов, участие которых планируется в организационном комитете / методической комиссии / жюри олимпиады`,
      text: 'ФИО и квалификация экспертов',
      template: (row) => {
        return getNamesFromArray(row.experts, ['name', 'qualification'], ', ', 'В организационном комитете / методической комиссии / жюри олимпиады участие международных экспертов не планируется')
      }
    },
    {
      dataField: 'fileId',
      cols: 1,
      rows: 1,
      order: 8,
      class: `align-start`,
      headerClass: 'd-flex flex-row justify-center px-2',
      variable: true,
      text: 'Положение об олимпиаде',
      headerTooltip: true,
      headerTooltipText: () => `Утверждённое положение об олимпиаде или при его отсутствии официальное письмо о сроках утверждения положения об олимпиаде и о сроках его предоставления`,
    },
  ]
}
